<template>
  <b-modal
    v-if="selected_bn && selected_bn.details"
    :id="modalId"
    :title="`Refine ${selected_bn.details.name}`"
    size="lg"
    :ok-title="creating ? 'Refining Behaviour...' : 'Refine Behaviour'"
    :ok-disabled="creating || bt_selected === '' || !bt_selected"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @show="getData"
    @ok.prevent="submitFunction"
  >
    <div>
      <div v-if="isNew" class="mt-50">
        <p class="">
          A new behaviour tree will be created with the following name where this behaviour node
          can be refined further.
        </p>
      </div>
      <b-form-group>
        <label for="bt-input">
          Behaviour Tree with refinement
        </label>
        <b-form-input
          id="bt-input"
          v-model="bt_selected"
          list="bt-list"
          placeholder="Enter BT Name"
          @update="updateWarning"
        />
        <b-form-datalist id="bt-list">
          <option v-for="bt in behaviourViews" :key="bt.id">
            {{ bt.name }}
          </option>
        </b-form-datalist>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import axiosIns from '@/libs/axios'
import AsyncModalMixin from './AsyncModalMixin'

export default {
  name: 'RefineBehaviourModal',
  mixins: [AsyncModalMixin],
  data: () => ({
    bt_selected: '',
    bt_selected_original: '',
    creating: false,
    isNew: false,
    modalId: 'refine-node-modal', // overrides the mixin modalId
  }),
  computed: {
    ...mapState({
      selected_bn: state => state.behaviours.selectedBehaviourNode,
      selected_bt: state => state.behaviours.selectedBehaviourTree,
      behaviourViews: state => state.behaviours.behaviourViews,
    }),
    bt_options() {
      return this.behaviourViews.map(x => ({
        value: x.id,
        html: x.name,
      }))
    },
  },
  methods: {
    getData() {
      if (this.selected_bn.details.type === 'FunctionNode') {
        this.bt_selected = this.selected_bn.subject.name
      } else {
        this.bt_selected = this.selected_bn.fn_derived.length > 0 ? this.selected_bn.fn_derived[0].name : ''
      }
      this.$store.dispatch('behaviours/getAllBehaviourTrees')
        .then(() => {
          if (this.behaviourViews.map(v => v.name).includes(this.bt_selected)) {
            this.isNew = false
          } else {
            this.isNew = true
          }
        })
      this.bt_selected_original = this.bt_selected
    },
    updateWarning() {
      if (this.behaviourViews.map(v => v.name).includes(this.bt_selected)) {
        this.isNew = false
      } else {
        this.isNew = true
      }
    },
    submitFunction(evt) {
      evt.preventDefault()
      this.creating = true
      const params = {}
      params.model = this.$store.state.model.id
      params.ref_name = this.bt_selected
      params.bt_selected_original = this.bt_selected_original
      const bt = this.behaviourViews.find(o => o.name === this.bt_selected)
      params.isNew = this.isNew
      if (bt) {
        params.ref_id = bt.id
      }
      params.source_id = this.selected_bn.details.id
      this.$http.post('/api/v2/behaviour/refine_behaviour', params)
        .then(({ data }) => {
          this.creating = false
          this.$bvModal.hide('refine-node-modal')
          this.resolveModal(data)
          this.$emit('refined', data)
        })
        .catch(r => {
          this.loading = false
          console.error(`[RefineBehaviour] submit failed - ${r}`)
          this.rejectModal(r)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.modal-footer-behaviour {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
